var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "550", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "titleStyle", style: "max-height: 63px !important" },
            [
              _c("v-col", { staticClass: "ml-n4", attrs: { cols: "11" } }, [
                _vm._v(" Histórico de atualização"),
              ]),
              _c(
                "v-col",
                { staticClass: "ml-4", attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { id: "btnCloseX", icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-0" }),
          _c(
            "v-card-text",
            {
              staticClass: "pa-2",
              style:
                "height: 420px !important; max-height: 300px !important;background-color: #e7e7fa",
              attrs: { id: "cardScroll2" },
            },
            _vm._l(_vm.historics, function (h) {
              return _c(
                "v-card-text",
                { key: h.id, staticClass: "h-title pt-4 pb-0" },
                [
                  _c("div", { staticClass: "row h-row mb-0 rounded" }, [
                    _c(
                      "div",
                      { staticClass: "col-7 pl-0" },
                      [
                        _c(
                          "v-subheader",
                          { staticStyle: { "max-height": "24px" } },
                          [_vm._v("Atualização realizada por ")]
                        ),
                        _c(
                          "v-subheader",
                          { staticStyle: { "max-height": "24px" } },
                          [_c("strong", [_vm._v(_vm._s(h.nome))])]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-5 pb-0" },
                      [
                        _c(
                          "v-subheader",
                          {
                            staticClass: "mt-0 h-line d-flex justify-end",
                            staticStyle: { "max-height": "24px" },
                          },
                          [_vm._v("Data/hora")]
                        ),
                        _c(
                          "v-subheader",
                          {
                            staticClass: "d-flex justify-end",
                            staticStyle: { "max-height": "24px" },
                          },
                          [_c("strong", [_vm._v(_vm._s(h.dataAtualizacao))])]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }),
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "h-title pb-5 h-footer",
              staticStyle: { "padding-top": "30px" },
            },
            [
              _c("strong", [
                _vm._v("Você visualizou todo histórico dos últimos 7 dias."),
              ]),
              _c("p", [
                _vm._v(
                  "Para ter acesso as atualizações anteriores entre em contato com a Agnes."
                ),
              ]),
            ]
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "export-btn",
                    disabled: _vm.historics == [],
                    color: "success",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.exportar()
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-download-outline")]),
                  _vm._v("Exportar"),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticStyle: { height: "48px" },
                  attrs: { color: "success", id: "btnClose" },
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v(" Fechar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }